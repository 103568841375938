<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <div :style="{'min-height:200px;':filterItem.showDistinct}">
            <BasicFilter v-if="!isTouch" :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" :hideOperators="hideOperators" @onInput = "onInput"></BasicFilter>

            <div class="mb-2 mt-2 pt-2 d-flex" :class="{ 'border-top': !isTouch }" v-if="filterItem.showDistinct">
                <input type="search" @search="enterSearch"  class="form-control form-control-sm rounded-0 mt-1" :placeholder="$t('Search...')" v-model="distincSearchValue">

                <button class="btn btn-link text-decoration-none px-1 py-0" @click = "sortOnCount(false)" :class="{'text-muted':item.distinctHandler.sortOnCount}" :title="$t('Sort alphabetically')">
                    <i v-if="item.distinctHandler.sortAlphabeticallyDirection == 'desc'" class="bi bi-sort-alpha-down-alt fs-5"></i>
                    <i v-if="item.distinctHandler.sortAlphabeticallyDirection == 'asc'" class="bi bi-sort-alpha-down fs-5"></i>
                </button>

                <button v-if="!item.distinctHandler.existsBinding" class="btn btn-link text-decoration-none px-1 py-0" @click = "sortOnCount(true)"  :class="{'text-muted':!item.distinctHandler.sortOnCount}" :title="$t('Sort on count')">
                    <i v-if="item.distinctHandler.sortOnCountDirection == 'asc'" class="bi bi-sort-down-alt fs-5"></i>
                    <i v-if="item.distinctHandler.sortOnCountDirection == 'desc'" class="bi bi-sort-down fs-5"></i>
                </button>
            </div>

            <div v-if="isLoading && filterItem.showDistinct" class="d-flex" style="height:150px;" >
                <div class="spinner-border m-auto" style="width:2rem;height:2rem;" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
 
            <template v-if="data.length > 0 && !isLoading && filterItem.showDistinct" >
                <div class="form-check mx-2">
                        
                        <input class="form-check-input" type="checkbox" @click="selectAll($event)" :checked="isAllSelected()" :id="id">
                        
                        <!--<input class="form-check-input" type="checkbox" @click="selectAll($event)" :id="id">-->
                        <label class="form-check-label" :for="id">
                            {{$t('Select all')}} 
                        </label>
                </div>
                <div class="ms-1 border position-relative" style="max-height: 250px; overflow: hidden auto;" ref="elRef" @scroll="handleScroll">
                    <div class="wrapper" :style="{ height: height + 'px' }">
                        <div class="form-check mx-1  position-absolute" style="height:24px;width:97%" :style="{ 'transform': 'translateY('+item.pos + 'px)' }" v-for="(item, index) in scrollData" :key="item.index">
                            <input class="form-check-input" type="checkbox" @input="addToFilter($event, item.item)" :id="item.item._key + '' + item.index + id" v-model="item.item._isSelected">
                            <label class="form-check-label text-truncate position-relative" style="max-width: 100%; padding-top: 1px;" :for="item.item._key + '' + item.index + id" :title="item.item._description ?? item.item._value + ' (' + item.item.Count + ')'">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="text-truncate">{{ item.item._description ?? item.item._value }}</div>
                                    <div v-if="!hideCounts" class="ms-1">({{ item.item.Count }})</div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </OMediaQueryProvider>
</template>

<script setup>
    import { defineProps, ref, onMounted } from 'vue';
    import { useLegacyVirtualScroll as useVirtualScroll } from 'o365-vue-utils';
    import BasicFilter from './components.FilterEditor.Basic.vue';
    import { OMediaQueryProvider } from 'o365-ui-components';
    import { computed } from 'vue';
    
    const props = defineProps({
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });

    const item = props.filterItem;
    const data = ref([]);
    const isLoading = ref(true);
    const height = ref(0);
    const elRef = ref(null);
    
    const {handleScroll, scrollData} = useVirtualScroll({
        dataRef: data.value,
        itemSize: 24,
        itemsToRender: 40,
        // buffer: 0,
        recycleList: true,
        elementRef: elRef
    });

    const hideCounts = computed(() => props.filterItem.distinctOptions?.hideCounts === true);

    const id = "selectalldistinct" + Math.floor(Math. random() * 100);
    const targetValue = item.distinctHandler.distinctTargetColumn??item.distinctHandler.distinctColumn??item.distinctHandler.targetColumn??item.distinctHandler.column;
    const displayValue = item.distinctHandler.distinctColumn??(item.distinctHandler.targetColumn?item.distinctHandler.column:null);

    const enterSearch = () => {
        if (searchDebounce) { window.clearTimeout(searchDebounce); }
        updateData(true);
    }
    const existsBinding = item.distinctHandler.exist??item.distinctHandler.existsBinding;

    const updateData = (pReload) => {
        if(!item.showDistinct) return;
        isLoading.value = true;
        const isInlist = item.isInListUsed || item.operator == 'exists_clause';
        let vCurrentItems = isInlist?(item.selectedValue ?? []):[];
        if(isInlist && typeof vCurrentItems == 'string'){
            vCurrentItems = [vCurrentItems];
        }
        
        item.distinctHandler.getData(pReload).then(function(pData) {
            data.value.splice(0,data.value.length);

            pData.forEach((pItem, index) => {
                pItem["_isSelected"] = (vCurrentItems.findIndex(x=>x==parseNull(pItem[targetValue]) || x==parseNull(pItem[displayValue])) !== -1)?true:false;
                pItem["_key"] = Math.floor(Math. random() * 100) ;
                pItem["_value"] = pItem[targetValue] ?? $t("-blank-");
                pItem["position"] = index*24;
              

                pItem['_description'] = pItem[displayValue]
                
                
                data.value.push(pItem);
            });

            isLoading.value = false;
            height.value = (data.value.length * 24) + 2;
        });
    }

    const addToFilter = (evt,pItem)=>{
        if (evt) pItem._isSelected = evt.target.checked;
        
        if(!item.isInListUsed) item.operator = "inlist";
        /*if(existsBinding){
            if(item.operator == "notinlist"){
                tem.operator = "not_exists_clause";
            }else{
                item.operator = "exists_clause";
            }
           // item.operator = "exists_clause";
        }*/

        combineCurrentAndSelected(pItem);

        if(!item.selectedValue){
            return;
        }
        if (item.selectedValue.length === 0) {
            item.selectedValue = null;
        }
    }
    const parseNull = (pValue) => {
        if (item.distinctType === 'string') {
            return pValue === null ? '' : pValue;
        } else {
            return pValue === null?0:pValue;
        }
    };

    const combineCurrentAndSelected = (pItem) =>{
        if(item.expressionValue && item.expressionValue.constructor == Array){
            //combine
            if(!pItem){
                item.expressionValue = [];
            }
            if(pItem && !pItem._isSelected && item.expressionValue){
                item.expressionValue.splice(item.expressionValue.indexOf(parseNull(pItem[targetValue])),1);
            }
            data.value.filter(x => x._isSelected).map(x => parseNull(x[targetValue])).forEach(val=>{

                if(item.expressionValue && item.expressionValue.indexOf(val) == -1){
                    item.expressionValue.push(val);
                }
            })
            item.selectedValue = item.expressionValue;
        }else{
            //just assign
            item.selectedValue = data.value.filter(x => x._isSelected).map(x => parseNull(x[targetValue]));
            item.expressionValue = item.selectedValue;
        }

        const customFilterFunction = item?.options?.filterParams?.autocomplete?.beforeApply;
        if (customFilterFunction) {
            customFilterFunction({
                selected: data.value,
                filterItem: item,
                value: item.selectedValue,
                display: item.expressionValue
            })
        }
    }

    const isAllSelected = () => {
        return data.value.filter(x => x._isSelected).length === data.value.length;
    }

    const selectAll = (evt) => {
        data.value.forEach(item => {
            item._isSelected = evt.target.checked;
        });
        
        addToFilter();    
    }

    const sortOnCount = (pSort) => {
        if (item.distinctHandler.setSortOnCount(pSort)) {
            updateData(true);
        }
    }

    const distincSearchValue = computed({
        get() {
            return item.distinctHandler.search;
        },
        set(newValue) {
            item.distinctHandler.search = newValue;;
            debounceSearch();
        }
    });

    let searchDebounce = null;
    function debounceSearch(e) {
        if (searchDebounce) { window.clearTimeout(searchDebounce); }
        searchDebounce = window.setTimeout(() => {
            enterSearch();
            searchDebounce = null;
        }, 500);
    }

    const onInput = () =>{
        if(!item.selectedValue){
           // updateData();
             data.value.filter(x=>x._isSelected).forEach(it=>{
                it._isSelected = false;
             })
        }
    }

    onMounted(() => {
        item.distinctHandler.setDataObject(props.filterObject.dataObject);
        updateData();
    });
</script>
